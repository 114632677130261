<template>
  <div class="box">
    <!--<div class="top">-->
    <!--<img src=../assets_ch/back.png" alt="" @click="back">-->
    <!--返回-->
    <!--</div>-->
    <h3 class="title">{{ details.newsTitle }}</h3>
    <div class="details" v-html="details.newsHtml"></div>
    <img :src="imagesAddressCommon + '/fenxiang.png'" class="fenxiang" @click="tofenxiang" v-show="isshowfenxiang" />
  </div>
</template>

<script>
import $ from "jquery";
import { enumsImages } from "@/utils/enum.js";
export default {
  name: "newsDetails",
  data() {
    return {
      id: "",
      details: {},
      isshowfenxiang: false,
      imagesAddressCommon: enumsImages.addressCommon,
    };
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.getDetails();
    this.judgeApp();
  },
  methods: {
    // 判断是否是app内部
    judgeApp() {
      // 需要ios 安卓传递参数
      var u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        try {
          let isApp = window.Android.isApp();
          if (isApp == "android_yuanzilian") {
            this.isshowfenxiang = true;
          } else {
            this.isshowfenxiang = false;
          }
        } catch (err) {
          this.isshowfenxiang = false;
        }
      }
      if (isiOS) {
        try {
          //1、通知IOS 传参数; isApp ios方法
          window.webkit.messageHandlers.isApp.postMessage({});
          window.isApp = this.isApp;
        } catch (err) {
          this.isshowfenxiang = false;
        }
      }
    },
    //获取ios
    isApp: function (value) {
      if (value == "ios_yuanzilian") {
        this.isshowfenxiang = true;
      } else {
        this.isshowfenxiang = false;
      }
    },
    back() {
      history.go(-1);
    },
    getDetails() {
      // server.findNewsById({newsId:this.id}).then(res => {
      //     // console.log(res.data.data)
      //     this.details = res.data.data.data;
      // })
      let that = this,
        data = {
          newsId: this.id,
        },
        url = "https://official.juhaowu.cn/api/news/findNewsById",
        url_en = "https://en.atoshi.info/api/news/findNewsById"
      $.ajax({
        type: "post", //提交方式
        url: that.$store.state.isEn ? url_en : url, //路径
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify(data), // 数据
        success: function (res) {
          //返回数据根据结果进行相应的处理
          if (res.code == 100) {
            let resData = res.data.data;
            that.details = resData;
          }
        },
      });
    },
    tofenxiang() {
      // let newsurl='https://chplus.atoshi.org/#/newsDetails?id=' + this.id
      let newsurl = window.location.origin + "/#/newsDetails?id=" + this.id;
      let _this = this;
      var userAgent = navigator.userAgent;
      if (userAgent.indexOf("Android") > -1) {
        // console.log("newsurl",newsurl)
        // console.log("this.details",this.details)
        window.Android.shareNewsFromWebsite(
          _this.details.newsTitle,
          newsurl,
          _this.details.readCoverImage
        );
      } else if (/^.+(Mobile\/\w+)\s?$/.test(userAgent)) {
        let datas = JSON.stringify({
          fxtitle: _this.details.newsTitle,
          fxurl: newsurl,
          fxcoverimg: _this.details.readCoverImage,
        });
        window.webkit.messageHandlers.shareNewsToiPhone.postMessage(datas);
      }
    },
  },
};
</script>

<style scoped>
/* .top {
  line-height: 1.3rem;
  height: 1.3rem;
  background: #fff;
  color: #666666;
  font-size: 0.3rem;
  border-bottom: 0.12rem solid #f5f5f5;
}
.top img {
  margin-left: 0.3rem;
  vertical-align: middle;
  width: 0.3rem;
  height: 0.31rem;
} */
.title {
  padding: 0.3rem 0.3rem 0rem;
  text-align: center;
  border-bottom: 1px solid #000000;font-size: 0.3rem
}
/deep/.details {
  padding: 0.2rem 0.3rem 0;font-size: 0.3rem;
}
/deep/.details p {
  width: 6.9rem !important;
  margin: 0 auto;
  /* text-indent: 2em; */
}

/deep/.details img {
  text-indent: 0em;
  width: 6.9rem !important;
  height: auto !important;
  margin: 0.1rem auto;
  /* margin-left: -0.57rem; */
}
/deep/.details a {
  color: #3f9ce8;
  text-decoration: underline;
  display: inline-block;
  width: 6.9rem;
  word-wrap: break-word;
}
.fenxiang {
  width: 0.7rem;
  height: 0.7rem;
  position: fixed;
  bottom: 1.1rem;
  right: 0.3rem;
  z-index: 1000;
}
</style>
